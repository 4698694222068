<template>
  <div class="question">
    <img src="@assets/images/questionTr.jpg" />
    <div class="container">
      <form>
        <div class="tips">各位学员：</div>
        <p>感谢您参与本次密训，耽误您几分钟时间请您协助完成此份调查问卷，您的评价对于我们改进培训工作来说非常重要。请在相对应的括号内打“√”。谢谢您的配合！</p>

        <div class="box">
          <span>课程名称：</span>
          <van-dropdown-menu title="请选择课程名称">
            <van-dropdown-item
              v-model="lesson_id"
              :options="lessonData"
              @change="changeQuestion()"
            />
            <!-- <van-dropdown-item v-model="lesson_id" :options="lessonData" @change="submitQuestion('query')"/> -->
          </van-dropdown-menu>
        </div>
        <div class="box">
          <span>主讲老师：</span>
          <input
            type="text"
            maxlength="20"
            v-model="teacher"
            placeholder="请输入主讲老师名称"
          />
        </div>

        <div class="title">培训组织方面</div>
        <div class="radio-title">1、在参加密训之前您得到的通知是否及时？</div>
        <van-radio-group v-model="form.a1">
          <van-radio icon-size=".32rem" name="100">很好</van-radio>
          <van-radio icon-size=".32rem" name="80">好</van-radio>
          <van-radio icon-size=".32rem" name="60">一般</van-radio>
          <van-radio icon-size=".32rem" name="40">不好</van-radio>
          <van-radio icon-size=".32rem" name="20">差</van-radio>
          <input
            type="text"
            maxlength="50"
            v-model="cause.a1"
            placeholder="原因说明..."
          />
        </van-radio-group>

        <div class="radio-title">2、您认为本次密训活动场所安排是否满意？</div>
        <van-radio-group v-model="form.a2">
          <van-radio icon-size=".32rem" name="100">很好</van-radio>
          <van-radio icon-size=".32rem" name="80">好</van-radio>
          <van-radio icon-size=".32rem" name="60">一般</van-radio>
          <van-radio icon-size=".32rem" name="40">不好</van-radio>
          <van-radio icon-size=".32rem" name="20">差</van-radio>
          <input type="text" maxlength="50" v-model="cause.a2" placeholder="原因说明..." />
        </van-radio-group>

        <div class="radio-title">3、您认为本次密训所用的视听器材是否满意？</div>
        <van-radio-group v-model="form.a3">
          <van-radio icon-size=".32rem" name="100">很好</van-radio>
          <van-radio icon-size=".32rem" name="80">好</van-radio>
          <van-radio icon-size=".32rem" name="60">一般</van-radio>
          <van-radio icon-size=".32rem" name="40">不好</van-radio>
          <van-radio icon-size=".32rem" name="20">差</van-radio>
          <input type="text" maxlength="50" v-model="cause.a3" placeholder="原因说明..." />
        </van-radio-group>

        <div class="radio-title">4、您认为密训时间安排的长短是否合理？</div>
        <van-radio-group v-model="form.a4">
          <van-radio icon-size=".32rem" name="100">很好</van-radio>
          <van-radio icon-size=".32rem" name="80">好</van-radio>
          <van-radio icon-size=".32rem" name="60">一般</van-radio>
          <van-radio icon-size=".32rem" name="40">不好</van-radio>
          <van-radio icon-size=".32rem" name="20">差</van-radio>
          <input
            type="text"
            maxlength="50"
            v-model="cause.a4"
            placeholder="原因说明..."
          />
        </van-radio-group>

        <div class="radio-title">5、助教老师的服务水平和主动性怎样？</div>
        <van-radio-group v-model="form.a5">
          <van-radio icon-size=".32rem" name="100">很好</van-radio>
          <van-radio icon-size=".32rem" name="80">好</van-radio>
          <van-radio icon-size=".32rem" name="60">一般</van-radio>
          <van-radio icon-size=".32rem" name="40">不好</van-radio>
          <van-radio icon-size=".32rem" name="20">差</van-radio>
          <input
            type="text"
            maxlength="50"
            v-model="cause.a5"
            placeholder="原因说明..."
          />
        </van-radio-group>

        <div class="radio-title">6、主持人在调节课堂气氛方面做得如何？</div>
        <van-radio-group v-model="form.a6">
          <van-radio icon-size=".32rem" name="100">很好</van-radio>
          <van-radio icon-size=".32rem" name="80">好</van-radio>
          <van-radio icon-size=".32rem" name="60">一般</van-radio>
          <van-radio icon-size=".32rem" name="40">不好</van-radio>
          <van-radio icon-size=".32rem" name="20">差</van-radio>
          <input
            type="text"
            maxlength="50"
            v-model="cause.a6"
            placeholder="原因说明..."
          />
        </van-radio-group>

        <div class="radio-title">7、您对本次密训组织和安排的整体印象怎样？</div>
        <van-radio-group v-model="form.a7">
          <van-radio icon-size=".32rem" name="100">很好</van-radio>
          <van-radio icon-size=".32rem" name="80">好</van-radio>
          <van-radio icon-size=".32rem" name="60">一般</van-radio>
          <van-radio icon-size=".32rem" name="40">不好</van-radio>
          <van-radio icon-size=".32rem" name="20">差</van-radio>
          <input
            type="text"
            maxlength="50"
            v-model="cause.a7"
            placeholder="原因说明..."
          />
        </van-radio-group>
        <div  v-if="is_show">
        <div class="title">生活服务方面</div>
          <div class="radio-title">1、对于住宿场所是否满意？</div>
          <van-radio-group v-model="form1.d1">
              <van-radio icon-size=".32rem" name="100">很好</van-radio>
              <van-radio icon-size=".32rem" name="80">好</van-radio>
              <van-radio icon-size=".32rem" name="60">一般</van-radio>
              <van-radio icon-size=".32rem" name="40">不好</van-radio>
              <van-radio icon-size=".32rem" name="20">差</van-radio>
              <input
                      type="text"
                      maxlength="50"
                      v-model="cause.d1"
                      placeholder="原因说明..."
              />
          </van-radio-group>

          <div class="radio-title">2、对于食堂就餐是否满意？</div>
          <van-radio-group v-model="form1.d2">
              <van-radio icon-size=".32rem" name="100">很好</van-radio>
              <van-radio icon-size=".32rem" name="80">好</van-radio>
              <van-radio icon-size=".32rem" name="60">一般</van-radio>
              <van-radio icon-size=".32rem" name="40">不好</van-radio>
              <van-radio icon-size=".32rem" name="20">差</van-radio>
              <input type="text" maxlength="50" v-model="cause.d2" placeholder="原因说明..." />
          </van-radio-group>

          <div class="radio-title">3、对行政前台服务是否满意？</div>
          <van-radio-group v-model="form1.d3">
              <van-radio icon-size=".32rem" name="100">很好</van-radio>
              <van-radio icon-size=".32rem" name="80">好</van-radio>
              <van-radio icon-size=".32rem" name="60">一般</van-radio>
              <van-radio icon-size=".32rem" name="40">不好</van-radio>
              <van-radio icon-size=".32rem" name="20">差</van-radio>
              <input type="text" maxlength="50" v-model="cause.d3" placeholder="原因说明..." />
          </van-radio-group>

          <div class="radio-title">4、对学校门卫室工作是否满意？</div>
          <van-radio-group v-model="form1.d4">
              <van-radio icon-size=".32rem" name="100">很好</van-radio>
              <van-radio icon-size=".32rem" name="80">好</van-radio>
              <van-radio icon-size=".32rem" name="60">一般</van-radio>
              <van-radio icon-size=".32rem" name="40">不好</van-radio>
              <van-radio icon-size=".32rem" name="20">差</van-radio>
              <input
                      type="text"
                      maxlength="50"
                      v-model="cause.d4"
                      placeholder="原因说明..."
              />
          </van-radio-group>

          <div class="radio-title">5、对校区整体卫生环境是否满意？</div>
          <van-radio-group v-model="form1.d5">
              <van-radio icon-size=".32rem" name="100">很好</van-radio>
              <van-radio icon-size=".32rem" name="80">好</van-radio>
              <van-radio icon-size=".32rem" name="60">一般</van-radio>
              <van-radio icon-size=".32rem" name="40">不好</van-radio>
              <van-radio icon-size=".32rem" name="20">差</van-radio>
              <input
                      type="text"
                      maxlength="50"
                      v-model="cause.d5"
                      placeholder="原因说明..."
              />
          </van-radio-group>
        </div>
        <div class="title">课程安排及讲师评价</div>
        <div class="radio-title">1、对此课程的总体评价？</div>
        <van-radio-group v-model="form.b1">
          <van-radio icon-size=".32rem" name="100">很好</van-radio>
          <van-radio icon-size=".32rem" name="80">好</van-radio>
          <van-radio icon-size=".32rem" name="60">一般</van-radio>
          <van-radio icon-size=".32rem" name="40">不好</van-radio>
          <van-radio icon-size=".32rem" name="20">差</van-radio>
          <input
            type="text"
            maxlength="50"
            v-model="cause.b1"
            placeholder="原因说明..."
          />
        </van-radio-group>

        <div class="radio-title">2、课程内容对你的帮助？</div>
        <van-radio-group v-model="form.b2">
          <van-radio icon-size=".32rem" name="100">很好</van-radio>
          <van-radio icon-size=".32rem" name="80">好</van-radio>
          <van-radio icon-size=".32rem" name="60">一般</van-radio>
          <van-radio icon-size=".32rem" name="40">不好</van-radio>
          <van-radio icon-size=".32rem" name="20">差</van-radio>
          <input
            type="text"
            maxlength="50"
            v-model="cause.b2"
            placeholder="原因说明..."
          />
        </van-radio-group>

        <div class="radio-title">3、课程实操的适用性？</div>
        <van-radio-group v-model="form.b3">
          <van-radio icon-size=".32rem" name="100">很好</van-radio>
          <van-radio icon-size=".32rem" name="80">好</van-radio>
          <van-radio icon-size=".32rem" name="60">一般</van-radio>
          <van-radio icon-size=".32rem" name="40">不好</van-radio>
          <van-radio icon-size=".32rem" name="20">差</van-radio>
          <input type="text" maxlength="50" v-model="cause.b3" placeholder="原因说明..." />
        </van-radio-group>

        <div class="radio-title">4、讲师的讲课方式是否满意？</div>
        <van-radio-group v-model="form.b4">
          <van-radio icon-size=".32rem" name="100">很好</van-radio>
          <van-radio icon-size=".32rem" name="80">好</van-radio>
          <van-radio icon-size=".32rem" name="60">一般</van-radio>
          <van-radio icon-size=".32rem" name="40">不好</van-radio>
          <van-radio icon-size=".32rem" name="20">差</van-radio>
          <input
            type="text"
            maxlength="50"
            v-model="cause.b4"
            placeholder="原因说明..."
          />
        </van-radio-group>

        <div class="radio-title">5、讲师实战经验及解决问题的能力？</div>
        <van-radio-group v-model="form.b5">
          <van-radio icon-size=".32rem" name="100">很好</van-radio>
          <van-radio icon-size=".32rem" name="80">好</van-radio>
          <van-radio icon-size=".32rem" name="60">一般</van-radio>
          <van-radio icon-size=".32rem" name="40">不好</van-radio>
          <van-radio icon-size=".32rem" name="20">差</van-radio>
          <input
            type="text"
            maxlength="50"
            v-model="cause.b5"
            placeholder="原因说明..."
          />
        </van-radio-group>

        <div class="radio-title">6、讲师授课速度的掌握情况？</div>
        <van-radio-group v-model="form.b6">
          <van-radio icon-size=".32rem" name="100">很好</van-radio>
          <van-radio icon-size=".32rem" name="80">好</van-radio>
          <van-radio icon-size=".32rem" name="60">一般</van-radio>
          <van-radio icon-size=".32rem" name="40">不好</van-radio>
          <van-radio icon-size=".32rem" name="20">差</van-radio>
          <input
            type="text"
            maxlength="50"
            v-model="cause.b6"
            placeholder="原因说明..."
          />
        </van-radio-group>

        <div class="radio-title">7、讲师在调节课堂气氛方面？</div>
        <van-radio-group v-model="form.b7">
          <van-radio icon-size=".32rem" name="100">很好</van-radio>
          <van-radio icon-size=".32rem" name="80">好</van-radio>
          <van-radio icon-size=".32rem" name="60">一般</van-radio>
          <van-radio icon-size=".32rem" name="40">不好</van-radio>
          <van-radio icon-size=".32rem" name="20">差</van-radio>
          <input
            type="text"
            maxlength="50"
            v-model="cause.b7"
            placeholder="原因说明..."
          />
        </van-radio-group>

        <div class="radio-title">8、讲师在回答学员问题方面？</div>
        <van-radio-group v-model="form.b8">
          <van-radio icon-size=".32rem" name="100">很好</van-radio>
          <van-radio icon-size=".32rem" name="80">好</van-radio>
          <van-radio icon-size=".32rem" name="60">一般</van-radio>
          <van-radio icon-size=".32rem" name="40">不好</van-radio>
          <van-radio icon-size=".32rem" name="20">差</van-radio>
          <input
            type="text"
            maxlength="50"
            v-model="cause.b8"
            placeholder="原因说明..."
          />
        </van-radio-group>

        <div class="radio-title">9、本次密训还有哪些问题没有得到解决？</div>
        <textarea v-model="form.b9"></textarea>

        <div class="title">您对本次密训的评语</div>
        <textarea v-model="c1"></textarea>
        <div class="radio-title">是否同意我们在其他资料上引用您的这句话？</div>
        <van-radio-group v-model="c2">
          <van-radio icon-size=".32rem" value="1" name="1">是</van-radio>
          <van-radio icon-size=".32rem" value="2" name="2">否</van-radio>
        </van-radio-group>

        <div class="title">您对公司的课程和管理有什么建议和意见？</div>
        <textarea v-model="opinion"></textarea>
        <van-radio-group v-model="is_first">
          <div class="radio-title" style="margin: 0.2rem 0 0.2rem 0">
            第几次参加密训？
          </div>
          <!-- <van-radio icon-size=".32rem" value="1">是</van-radio> -->
          <van-radio icon-size=".32rem" name="1">首次</van-radio>
          <van-radio icon-size=".32rem" name="2">2次</van-radio>
          <van-radio icon-size=".32rem" name="3">3次</van-radio>
          <van-radio icon-size=".32rem" name="4">4次</van-radio>
        </van-radio-group>

        <div class="submit" @click="submitQuestion('submit')">提交问卷</div>
        <h4>
          再次感谢您的配合！
          <br />期待与您再次相会在聚亿美大家庭！
        </h4>
      </form>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { RadioGroup, Radio, DropdownMenu, DropdownItem } from "vant";
import { questionLesson, questionnaire } from "@api/user";
// 引入防抖和节流函数
import globalFunction from "@utils/globalFunction";
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
export default {
  data() {
    return {
      id: "",
      lesson_id: "",
      teacher: "",
      list:[],
      category:[],
      is_show:true,
      lessonData: [],
      form: {
        a1: "",
        a2: "",
        a3: "",
        a4: "",
        a5: "",
        a6: "",
        a7: "",
        b1: "",
        b2: "",
        b3: "",
        b4: "",
        b5: "",
        b6: "",
        b7: "",
        b8: "",
        b9: ""
      },
      form1:{
          d1:"",
          d2:"",
          d3:"",
          d4:"",
          d5:""
      },
      cause: {
        a1: "",
        a2: "",
        a3: "",
        a4: "",
        a5: "",
        a6: "",
        a7: "",
        b1: "",
        b2: "",
        b3: "",
        b4: "",
        b5: "",
        b6: "",
        b7: "",
        b8: "",
        d1:"",
        d2:"",
        d3:"",
        d4:"",
        d5:""
      },
      c1: "",
      c2: "",
      opinion: "",
      is_first: "",
      flag: true,
    };
  },
  created() {
    this.id = this.$route.query.id;
    this.getLesson();
  },
  methods: {
    // 获取课程
    getLesson() {
      questionLesson().then((res) => {
        const data = res.data.data;
        this.list = res.data.data;
        this.category = res.data.category;
        const array = [];
        for (const key in data) {
          if (key == this.id) {
            array.unshift({
              value: key,
              text: data[key].bookname,
            });
            //this.is_show = this.category.some((item) => item == data[key].cid);
          } else {
            array.push({
              value: key,
              text: data[key].bookname,
            });
          }
        }
        let boo = array.some((item) => item.value == this.id);
        if (!boo) array.unshift({ value: 0, text: "请选择课程" });
        this.lessonData = array;
        this.lesson_id = array[0].value;
        if (this.id && this.id != 0) this.changeQuestion();
      });
    },
    // 防抖函数
    // debounce(fn, wait) {
    //   return (res) => {
    //     if (this.timeout !== null) {
    //       clearTimeout(this.timeout);
    //     }
    //     this.timeout = setTimeout(() => fn(res), wait);
    //   };
    // },
    // 请求提交接口
    sendAjax(type) {
      let params = {};
      let form = this.form;
      let form1 = this.form1;

      if (type == "query") {
        params = {
          lesson_id: this.lesson_id,
        };
      } else {
        // 必填提示
        if (!this.flag) {
          this.$dialog.error("您已经提交过了哦");
          return;
        }
        if (!this.lesson_id) {
          this.$dialog.error("课程名称必填");
          return;
        }
        if (!this.teacher) {
          this.$dialog.error("主讲老师必填");
          return;
        }
        for (const key in form) {
          if (!form[key]) {
            this.$dialog.error("【培训组织方面】和【课程安排及讲师评价】有未选择项！");
            return;
          }
        }
        if(this.is_show){
            for (const key in form1) {
                if (!form1[key]) {
                    this.$dialog.error("【生活服务方面】有未选择项！");
                    return;
                }
            }
        }

        params = {
          lesson_id: this.lesson_id,
          teacher: this.teacher,
          c1: this.c1,
          c2: this.c2,
          opinion: this.opinion,
          is_first: this.is_first,
          cause: JSON.stringify(this.cause),
          ...this.form,
          ...this.form1,
        };
      }

      questionnaire(type, params)
        .then(() => {
          if (type == "query") {
            this.flag = true;
          } else {
            this.$dialog.success("提交成功！");
            setTimeout(() => {
                // this.$router.back();
              location.reload();
              }, 1500);
          }
        })
        .catch((err) => {
          this.flag = false;
          this.$dialog.error(err.msg);
        });
    },
    // 提交
    submitQuestion:globalFunction.debounce(function(type){
      this.sendAjax(type)
    },500),
    changeQuestion() {
        if(this.lesson_id){
            let cid = this.list[this.lesson_id].cid;
            this.is_show = this.category.some((item) => item == cid);
        }
    },
  },
};
</script>

<style scoped lang="scss">
.question {
  padding: 0.2rem;
  background: linear-gradient(#fff, #354af5);
  color: black;
  img {
    width: 100%;
    height: auto;
  }
  .container {
    background: white;
    font-size: 0.24rem;
    padding: 0.2rem;
    .box {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      height: 0.6rem;
      line-height: 0.6rem;
      span {
        width: 20%;
      }
      input {
        width: 78%;
        text-align: right;
        border-bottom: 1px solid #000;
      }
    }
    .tips {
      color: #354af5;
      border-bottom: 1px dashed #354af5;
      font-size: 0.32rem;
      line-height: 0.72rem;
    }
    p {
      text-indent: 2em;
      line-height: 0.4rem;
      margin: 0.2rem 0 0.3rem 0;
    }
    .van-radio-group {
      display: flex;
      flex-flow: row nowrap;
      input[type="text"] {
        margin-left: 0.2rem;
        width: 1.6rem;
        border-bottom: 1px solid #666;
      }
    }
    .title {
      font-weight: 700;
      font-size: 0.26rem;
      height: 0.5rem;
      line-height: 0.5rem;
      padding-left: 0.2rem;
      background: #f8f8f8;
      margin: 0.3rem 0;
    }
    .radio-title {
      margin: 0.3rem 0 0.15rem 0;
    }
    textarea {
      border: 1px solid #000;
      border-radius: 0.12rem;
      width: 100%;
      height: 2rem;
      padding: 0.2rem;
    }
    .submit {
      display: block;
      width: 100%;
      height: 0.8rem;
      line-height: 0.8rem;
      margin-bottom: 0;
      font-size: 0.32rem;
      background: #3e3efd;
      text-align: center;
      border-radius: 0.12rem;
      color: white;
    }
    h4 {
      margin: 0.2rem 0;
      font-size: 0.32rem;
      text-align: center;
      font-weight: 500;
    }
  }
  .van-dropdown-menu {
    width: 78%;
    height: 0.6rem;
    border: none;
    border-bottom: 1px solid #000;
    padding-right: 8px;
  }
}
</style>